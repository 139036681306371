<template>
  <b-modal
    id="add-user-modal"
    v-model="openModal"
    size="md"
    title="Supprimer une ressource"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      >
        <p v-if="resource">
          La ressource <strong>{{ resource.display_name }}</strong> 
          ainsi que tous les services associés seront définitivement supprimés.
        </p>
        <p>Êtes-vous sûr de vouloir continuer&#8239;?</p>
      </b-overlay>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="hide"
        >
          Annuler
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="deleteResource"
        >
          Confirmer la suppression
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'DeleteResourceModal',

  props: {
    open: {
      type: Boolean,
      default: false
    },
    resource: {
      type: Object,
      default: () => { return {}; }
    }
  },

  data() {
    return {
      openModal: false,
      loading: false
    };
  },

  watch: {
    open: function(newValue) {
      this.resetModal();
      this.openModal = newValue;
    }
  },

  methods: {
    resetModal() {
      this.openModal = false;
      this.loading = false;

    },
    deleteResource() {
      this.$emit('delete');
      this.$emit('hide');
    },
    hide() {
      this.$emit('hide');
    }
  }
};
</script>
