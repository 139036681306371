<template>
  <div
    class="checkox_container"
    :class="{ disabled: !publishResourceScenariosFound(resourceDatasetId) }"
  >
    <input
      v-model="hideState"
      type="checkbox"
      style="margin: 0 0.3rem; cursor: pointer;"
    >
    <label>
      {{ capitalizeFirstLetter($t('resource.hide')) }}
    </label>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import resourcesAPI from '@/api/resourcesAPI.js';

export default {
  name: 'PublicationCheckbox',

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      hideState: false,
      initiating: true
    };
  },

  computed: {
    ...mapState('resources', [
      'mainResourceDatasetRelation',
      'annexResourcesDatasetRelation',
    ]),
    ...mapGetters('resources', [
      'currentResourcePublishScenario',
      'currentResourceUnpublishScenario',
      'publishResourceScenariosFound'
    ]),
    ...mapGetters('datasets', [
      'currentDatasetMainResource',
      'currentDatasetAnnexResources'
    ]),

    resourceDatasetId() {
      if (this.isMain && this.currentDatasetMainResource) {
        return this.currentDatasetMainResource.resourcedataset_id;
      } else if (!this.isMain && this.currentDatasetAnnexResources) {
        const foundAnnex = this.currentDatasetAnnexResources.find(el => el.id === this.metaResource.id);
        if (foundAnnex) {
          return foundAnnex.resourcedataset_id;
        }
      }
      return null;
    }
  },

  watch: {
    mainResourceDatasetRelation: {
      deep: true,
      handler(newValue) {
        if (this.isMain) {
          if (newValue !== null) {
            this.hideState = !newValue.publish;
          } else {
            this.hideState = false;
          }
        }
      }
    },
    annexResourcesDatasetRelation: {
      deep: true,
      handler(newValue) {
        const annexDatasetRelation = newValue.find(el => el.id === this.resourceDatasetId);
        if (annexDatasetRelation && annexDatasetRelation.publish !== null) {
          this.hideState = !annexDatasetRelation.publish;
        } else {
          this.hideState = false;
        }
      }
    },
    hideState(newValue, oldValue) {
      if (newValue !== oldValue && this.resourceDatasetId) {
        if (this.publishResourceScenariosFound(this.resourceDatasetId) && !this.initiating) {
          if (newValue) {
            this.runResourcePublishScenario(this.currentResourceUnpublishScenario(this.resourceDatasetId).id);
          } else {
            this.runResourcePublishScenario(this.currentResourcePublishScenario(this.resourceDatasetId).id);
          }
        }
      }
    }
  },

  created() {
    this.initiating = true;

    if (this.isMain) {
      if (this.mainResourceDatasetRelation && this.mainResourceDatasetRelation.publish !== null) {
        this.hideState = !this.mainResourceDatasetRelation.publish;
      } else {
        this.hideState = false;
      }
    } else {
      const annexDatasetRelation = this.annexResourcesDatasetRelation.find(el => el.id === this.resourceDatasetId);
      if (annexDatasetRelation && annexDatasetRelation.publish !== null) {
        this.hideState = !annexDatasetRelation.publish;
      } else {
        this.hideState = false;
      }
    }
    this.$nextTick(() => {
      this.initiating = false;
    });
  },

  methods: {
    async runResourcePublishScenario(scenarioId) {
      try {
        this.$emit('loading', true);
        await resourcesAPI.buildResourceIndex(this.resourceDatasetId, scenarioId);
        this.$emit('tasks');
        this.$emit('loading', false);
      } catch {
        this.$emit('loading', false);
      }
    },
  }

};
</script>