<template>
  <div class="resource card">
    <b-overlay
      :show="loading"
      :variant="'white'"
      :opacity="0.9"
      :blur="'2px'"
      style="z-index: 1040;"
    >
      <div class="card-header">
        <div class="header-top">
          <div class="resource_icon">
            <i class="bi bi-hdd-fill" />
          </div>
          <div
            v-if="resourceDataFormats.length && resource.dataformat && resource.dataformat.id"
            class="resource_format"
          >
            {{
              resourceDataFormats.length && resource.dataformat && resource.dataformat.id ?
                resourceDataFormats.find(el => el.id === resource.dataformat.id).codename :
                ''
            }}
          </div>
          <div class="resource_name">
            <div
              v-for="filePath of resource.href"
              :key="filePath"
            >
              {{ filePath.split('/').slice(-1)[0] }} 
              <i v-if="resource && resource.file">({{ fileSize }})</i>
            </div>
          </div>
        </div>
        <div class="header-bottom">
          <PublicationCheckbox
            :is-main="isMain"
            :meta-resource="metaResource"
            @loading="setLoading"
          />
          <b-button
            v-if="userData.is_superuser"
            variant="outline-secondary"
            @click="isUpdateFTPModalOpen = true"
          >
            {{ $t('buttons.edit') }}
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <ResourceAccordion
          :id="resource.id"
          :is-main="isMain"
          :form-config="$config.forms.resource.ftp"
          :meta-resource="metaResource"
          v-on="$listeners"
        />
      </div>
    </b-overlay>
    <UpdateFTPModal
      :open="isUpdateFTPModalOpen"
      :format="resourceDataFormats.find(el => el.id === resource.dataformat ? resource.dataformat.id : 1)"
      @hide="isUpdateFTPModalOpen = false"
      @update="modifyFTP"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { fileConvertSize } from '@/utils';
import resourcesAPI from '@/api/resourcesAPI';

import ResourceAccordion from '@/components/Resource/ResourceAccordion.vue';
import PublicationCheckbox from '@/components/Resource/PublicationCheckbox.vue';
import UpdateFTPModal from '@/components/Resource/modals/UpdateFTPModal.vue';

export default {
  name: 'FTPEditor',

  components: {
    ResourceAccordion,
    PublicationCheckbox,
    UpdateFTPModal
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      loading: false,
      scheduler: null,
      isUpdateFTPModalOpen: false
    };
  },

  computed: {
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('resources', [
      'resourceDataFormats',
    ]),

    fileSize() {
      return fileConvertSize(this.resource.file.size);
    },
  },

  async created() {
    if (this.resource['scenario-execution-frequency']) {
      const response = await resourcesAPI.getResourceExecutionFrequency(this.resource['scenario-execution-frequency']);
      if (response && response.results && response.results.length) {
        this.scheduler = response.results[0].scheduler;
      }
    }
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isUpdateFTPModalOpen = false;
    });
  },

  methods: {
    setLoading(e) {
      this.loading = e;
    },
    
    modifyFTP(e) {
      this.$emit('modify', {
        data: {
          id: this.resource.id,
          ...e.form
        },
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'ftp',
        resourceId: this.metaResource.id,
      });
      this.isUpdateFTPModalOpen = false;
    },
  }
};
</script>