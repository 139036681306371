<template>
  <b-toast
    :id="`dataset-task-toast-${task.id}`"
    v-model="visible"
    class="dataset-toast"
    variant="default"
    solid
    :no-auto-hide="!autoHide"
    :auto-hide-delay="10000"
    :toaster="toaster"
    :no-close-button="noCloseButton"
    @hide="$emit('hide', task.id)"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <b-img
          blank
          :blank-color="
            `#${task.warns && task.warns.length && task.state === 'SUCCESS' ?
              colors['WARNING'] : colors[task.state] ? colors[task.state] : '6c757d'}`
          "
          class="mr-2"
          width="12"
          height="12"
        />
        <strong class="mr-auto">
          {{ task.state_label }} <!-- {{ task.warns && task.warns.length ? $t('tasks.withWarnings') : '' }} -->
        </strong>
        <small class="text-muted mr-2">
          {{ task.stop_date ? new Date(task.stop_date).toLocaleString('fr-FR', {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
            hour: '2-digit',
            minute: "2-digit",
            second: "2-digit"
          }) : '' }}
        </small>
      </div>
    </template>
    <!-- {{ task.action }} -->
    <div class="link-container">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: 'TaskDetail', params: { id: task.id }}"
        custom
        class="custom-link"
      >
        <span
          role="link"
          @click="navigate"
        >
          {{ task.action }}
        </span>
      </router-link>
    </div>
  </b-toast>
</template>

<script>
export default {
  name: 'CustomToast',

  props: {
    static: {
      type: Boolean,
      default: false
    },
    noCloseButton: {
      type: Boolean,
      default: false
    },
    autoHide: {
      type: Boolean,
      default: false
    },
    toaster: {
      type: String,
      default: 'b-toaster-top-right'
    },
    task: {
      type: Object,
      default: () => {
        return {
          id: 1,
          visible: false,
          action: null,
          state: null,
          state_label: null
        };
      }
    }
  },

  data() {
    return {
      visible: false,
      colors: {
        SUCCESS: '55ffaa',
        FAILURE: 'ff5555',
        WARNING: 'fff033'
      }
    };
  },

  watch: {
    static(newValue) {
      this.visible = newValue;
    },
    task: {
      deep: true,
      handler(newValue) {
        if (Object.keys(newValue).includes('visible')) {
          this.visible = newValue.visible;
        }
      }
    }
  },

  created() {
    this.visible = this.task.visible ? this.task.visible : this.static;
  },

  methods: {
    hideToast() {
      this.task.visible = false;
    }
  }

};
</script>

<style lang="less" scoped>
.b-toast {
  z-index: 98;
}
.link-container {
  min-width: 100%;
}
.custom-link {
  cursor: pointer;
  color: @blue;
  display: block;
  min-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}
.custom-link:hover {
  text-shadow: @blue 1px 0 0;
}
</style>
