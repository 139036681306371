import { render, staticRenderFns } from "./UpdateFileUploadModal.vue?vue&type=template&id=236ff610&scoped=true"
import script from "./UpdateFileUploadModal.vue?vue&type=script&lang=js"
export * from "./UpdateFileUploadModal.vue?vue&type=script&lang=js"
import style0 from "./UpdateFileUploadModal.vue?vue&type=style&index=0&id=236ff610&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236ff610",
  null
  
)

export default component.exports