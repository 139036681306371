<template>
  <label class="toggleSwitch nolabel">
    <input
      v-model="value"
      type="checkbox"
      @change="toggle"
    >
    <a />
    <span>
      <span class="left-span">
        {{ left.label }}
      </span>
      <span class="right-span">
        {{ right.label }}
      </span>
    </span>											
  </label>
</template>

<script>
export default {
  name: 'TwoLabelsSwitch',

  props: {
    left: {
      type: Object,
      default: () => {
        return {
          label: 'Désactivé',
          value: 0
        };
      }
    },
    right: {
      type: Object,
      default: () => {
        return {
          label: 'Activé',
          value: 1
        };
      }
    },
    initialValue: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      value: false
    };
  },

  watch: {
    initialValue(newValue) {
      this.value = newValue;
    }
  },

  created() {
    this.value = this.initialValue;
  },

  methods: {
    toggle() {
      if (this.value) {
        this.$emit('toggle', this.right.value);
      } else {
        this.$emit('toggle', this.left.value);
      }
    }
  }
};
</script>

<style scoped lang="less">
.toggleSwitch span span {
	display: none;
}  
  
.toggleSwitch {
	display: inline-block;
	position: relative;
	overflow: visible;
	padding: 0;
	cursor: pointer;
	min-width: 200px;
	width: 100%;
	background-color: #fafafa;
	border: 2px solid #A9B2B9;
	border-radius: 8px;
	height: 46px;
}
.toggleSwitch * {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.toggleSwitch label,
.toggleSwitch > span {
	line-height: 30px;
	height: 30px;
	vertical-align: middle;
}
.toggleSwitch input:focus ~ a,
.toggleSwitch input:focus + label {
	outline: none;
}
.toggleSwitch label {
	position: relative;
	z-index: 3;
	display: block;
	width: 100%;
}
.toggleSwitch input {
	position: absolute;
	opacity: 0;
	z-index: 5;
}
.toggleSwitch > span {
	position: absolute;
	left: 0;
	width: calc(100% - 6px);
	margin: 0;
	text-align: left;
	white-space: nowrap;
  margin:0 3px;
}
.toggleSwitch > span span {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	display: block;
	width: 50%;
	margin-left: 50px;
	text-align: left;
	font-size: 1.2em;
	width: auto;
	left: 0;
	top: -1px;
	opacity: 1;
	width:40%;
	text-align: center;
  line-height:44px;
}
.toggleSwitch a {
	position: absolute;
	right: 50%;
	z-index: 4;
	display: block;
	top: 3px;
	bottom: 3px;
	padding: 0;
	left: 3px;
	width: 50%;
	background-color: @blue;
	border-radius: 4px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.toggleSwitch > span span:first-of-type {
	color: #FFF;
	opacity: 1;
	left: 0;
	margin: 0;
  width: 50%;
}
.toggleSwitch > span span:last-of-type {
	left:auto;
	right:0;
	color: #2F3234;
	margin: 0;
    width: 50%;
}
.toggleSwitch > span:before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: -2px;
	border-radius: 30px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}
.toggleSwitch input:checked ~ a {
	left: calc(50% - 3px);
}
.toggleSwitch input:checked ~ span span:first-of-type {
	left:0;
	color:#2F3234;
}
.toggleSwitch input:checked ~ span span:last-of-type {
	color:#FFF;
}
</style>