<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Datasets' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($tc('words.dataset', 2)) }}
      </span>
    </div>
    <b-container fluid>
      <b-overlay
        no-wrap
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        style="z-index: 1041;"
      />
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
            style="overflow-x: hidden;"
          >
            <ValidationObserver v-slot="{ handleSubmit }">
              <form>
                <!-- TOP ANCHOR FOR SPECIFIC COMPONENTS -->
                <div id="dataset-detail-top-anchor">
                  <component
                    :is="specificComponent.component"
                    v-for="[i, specificComponent] of topAnchoredSpecificComponents.entries()"
                    :key="`top-specific-component-${i}`"
                  />
                </div>
                <DatasetForm
                  ref="datasetForm"
                  :mode="'datasetDetail'"
                  :default-form="form"
                  @load="setLoader"
                  @change="setForm"
                  @remove-contact="removeContact"
                />
                
                <!-- BOTTOM ANCHOR FOR SPECIFIC COMPONENTS -->
                <div id="dataset-detail-bottom-anchor">
                  <component
                    :is="specificComponent.component"
                    v-for="[i, specificComponent] of bottomAnchoredSpecificComponents.entries()"
                    :key="`bottom-specific-component-${i}`"
                  />
                </div>

                <h3
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/file_document_sheet.svg"
                    alt="Icon files ressources"
                  > {{ $t('datasetDetail.mainResource.title') }}
                </h3>
                <b-row style="height: auto;">
                  <b-col
                    cols="12"
                  >
                    <MainResource
                      :loading="loadingResource"
                      :re-loading="reLoadindResource"
                      :disabled="disablingResource"
                      @set="setResource"
                      @modify="startModifyResource"
                      @update="startUpdateResource"
                      @delete="afterResourceChange"
                      @tasks="getLastDatasetTasks"
                    />
                    <div
                      v-if="!datasetMainResource || (datasetMainResource && !datasetMainResource.id)"
                      class="form-footer"
                      style="margin: 1em 0 0 0; justify-content: flex-end;"
                    >
                      <b-button
                        variant="primary"
                        :disabled="canLoadSource"
                        style="font-size: 1em;"
                        @click="handleSubmit(startCreateResource)"
                      >
                        {{ $t('buttons.load') }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <h3
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/files_ressources.svg"
                    alt="Icon files ressources"
                  > {{ $t('datasetDetail.annexes.title') }}
                </h3>
                <b-row style="height: auto;">
                  <b-col
                    cols="12"
                  >
                    <div
                      v-if="!annexes || !annexes.length"
                      class="form-row"
                    >
                      <div class="form-group col">
                        <p style="font-size: 0.9rem;">
                          {{ $t('datasetDetail.annexes.empty') }}
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <b-overlay
                        no-wrap
                        :show="loadingAnnexe"
                        :variant="'white'"
                        :opacity="0.7"
                        :blur="'2px'"
                        rounded="sm"
                      />
                      <Annexes
                        :annexes="annexes"
                        @modify="startModifyResource"
                        @update="startUpdateResource"
                        @delete="afterResourceChange"
                      />
                    </div>
                    <AddAnnexe
                      v-if="isAddingAnnexe"
                      :required="true"
                      @close="afterResourceChange"
                    />
                    <button
                      v-if="isAddingAnnexe"
                      class="add-button"
                      @click.prevent="isAddingAnnexe = false"
                    >
                      <b-icon-dash />
                      {{ $t('buttons.cancel') }}
                    </button>
                    <button
                      v-else
                      class="add-button"
                      @click.prevent="isAddingAnnexe = true"
                    >
                      <b-icon-plus />
                      {{ $t('datasetDetail.annexes.add') }}
                    </button>
                  </b-col>
                </b-row>

                <h3
                  v-if="currentDataset.children && currentDataset.children.length"
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/files_ressources.svg"
                    alt="Icon form description file"
                  > {{ $t('forms.datasets.children.title') }}
                </h3>

                <b-row style="height: auto;">
                  <b-col
                    cols="12"
                  >
                    <SearchablePaginatedTable
                      v-if="currentDataset.children && currentDataset.children.length"
                      :class-name="'dataset-children'"
                      :name="'datasetChildrenTable'"
                      :searchable="false"
                      :fields="childrenFields"
                      :rows="childrenRows"
                      :selectable="false"
                      :allow-deletion="false"
                    />
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <div class="side-card-container">
              <div class="card-scroller">
                <DatasetCard
                  :dataset="currentDataset"
                  @set="setThumbnail"
                />
                <div class="parent-dropdown">
                  <label>
                    {{ $t('forms.datasets.organisation.label') }}
                  </label>
                  <SearchUsergroups
                    v-model="form.usergroup"
                    :types="['organisation', 'user-group']"
                    :placeholder="$t('forms.datasets.organisation.help')"
                    :usergroup="form.usergroup"
                    :disabled="true"
                    :reset-after="false"
                    :limit="10"
                    @select="setUsergroup"
                    @close="setUsergroup"
                    @remove="setUsergroup"
                  />
                </div>
                <div class="parent-dropdown">
                  <label>
                    {{ $t('datasetDetail.parentDataset.title') }}
                  </label>
                  <SearchDatasets
                    v-model="form.parent"
                    :busy="parentBusy"
                    :dataset="form.parent"
                    :usergroup="form.usergroup"
                    :disabled="!form.usergroup"
                    :placeholder="$t('datasetDetail.parentDataset.placeholder')"
                    @select="setParentDataset"
                    @close="setParentDataset"
                  />
                </div>
                <div class="record_type-dropdown">
                  <label style="margin-bottom: 0.5rem;">
                    {{ $t('datasetDetail.recordType') }}
                  </label>
                  <b-overlay
                    :show="recordTypeBusy"
                    rounded="lg"
                    opacity="0.6"
                  >
                    <template #overlay>
                      <div class="d-flex align-items-center">
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <b-spinner
                          type="grow"
                          variant="dark"
                        />
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <span class="sr-only">Please wait...</span>
                      </div>
                    </template>
                    <Multiselect
                      v-model="form.recordType"
                      :options="datasetsRecordTypes"
                      track-by="choice"
                      label="label"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      :searchable="false"
                      :placeholder="$t('datasetDetail.recordType')"
                      @select="setDatasetRecordType"
                    />
                  </b-overlay>
                </div>
                <div class="permissions-dropdown">
                  <label style="margin-bottom: 0.5rem;">
                    {{ $t('datasetDetail.permissions') }}
                  </label>
                  <b-overlay
                    :show="permissionsBusy"
                    rounded="lg"
                    opacity="0.6"
                  >
                    <template #overlay>
                      <div class="d-flex align-items-center">
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <b-spinner
                          type="grow"
                          variant="dark"
                        />
                        <b-spinner
                          small
                          type="grow"
                          variant="secondary"
                        />
                        <span class="sr-only">Please wait...</span>
                      </div>
                    </template>
                    <Multiselect
                      v-model="permissionLevel"
                      :options="permissionLevels"
                      track-by="code"
                      label="label"
                      select-label=""
                      selected-label=""
                      deselect-label=""
                      :searchable="false"
                      :placeholder="$t('datasetDetail.permissions')"
                      @select="askForChangePermissionLevel"
                    />
                  </b-overlay>
                </div>
                <div
                  :class="{ disabled: !currentDatasetPublishScenario || !currentDatasetUnpublishScenario }"
                  class="access-permissions"
                  style="display: flex; justify-content: center;"
                >
                  <TwoLabelsSwitch
                    :left="{
                      label: capitalizeFirstLetter($t('words.unpublish')),
                      value: currentDatasetUnpublishScenario ? currentDatasetUnpublishScenario.id : null
                    }"
                    :right="{
                      label: capitalizeFirstLetter($t('words.publish')),
                      value: currentDatasetPublishScenario ? currentDatasetPublishScenario.id : null
                    }"
                    :initial-value="currentDatasetPublishState"
                    @toggle="runDatasetPublishScenario"
                  />
                </div>
                <div class="access-permissions">
                  <b-button
                    variant="outline-secondary"
                    @click="$router.push({
                      name: 'DatasetPermissions',
                      params: {
                        id: currentDataset.id,
                      }
                    })"
                  >
                    <img
                      src="@/assets/icons/access_unlock_open.svg"
                      alt="Icon access unlock open"
                    >
                    {{ $t('datasetDetail.access') }}
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="$router.push({
                      name: 'Logs',
                      params: {
                        object: 'dataset',
                        id: currentDataset.id,
                      }
                    })"
                  >
                    <b-icon
                      icon="clock-history"
                      font-scale="1.25"
                    />
                    {{ $t('words.activityFlow').toUpperCase() }}
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    @click="duplicateDataset"
                  >
                    <b-icon
                      icon="files"
                      font-scale="1.25"
                    />
                    {{ $t('buttons.duplicate').toUpperCase() }}
                  </b-button>
                </div>

                <div class="side-footer">
                  <b-button
                    variant="danger"
                    @click="askToDeleteDataset"
                  >
                    {{ $t('buttons.delete') }}
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    style="float: right"
                    @click="handleSubmit(saveDataset)"
                  >
                    {{ $t('buttons.saveChanges') }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
    <DeleteDatasetWithResources
      :open="isDeleteDatasetWithResourceOpen"
      :dataset="datasetWithResourceModalData"
      @delete-dataset-only="deleteDataset"
      @delete-dataset-and-resources="deleteDatasetWithResources"
      @close="isDeleteDatasetWithResourceOpen = false"
    />
    <SelectScenario
      :open="selectingScenario"
      :context="selectingScenarioContext"
      :scenarios="possibleScenarios"
      @create="finishCreateResource"
      @modify="finishModifyResource"
      @update="finishUpdateResource"
    />
    <CustomToast
      v-for="task of currentTasks"
      ref="custom-toast"
      :key="task.id"
      :auto-hide="true"
      :task="task"
      @hide="hideToast"
    />
  </div>
</template>

<script>
import  { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';
import dashboardAPI from '@/api/dashboardAPI.js';

import { ErrorService } from '@/services/error-service.js';

import { COVERAGE_OPTIONS } from '@/constants.js';

import AddAnnexe from '@/components/Forms/AddAnnexe.vue';
import Annexes from '@/components/Resource/Annexes.vue';
import CustomToast from '@/components/CustomToast.vue';
import DatasetCard from '@/components/Cards/DatasetCard.vue';
import DatasetForm from '@/components/Forms/DatasetForm.vue';
// import DatasetSource from '@/components/Dataset/DatasetSource.vue';
import DeleteDatasetWithResources from '@/components/Modals/DeleteDatasetWithResources.vue';
import MainResource from '@/components/Resource/MainResource.vue';
import SelectScenario from '@/components/Modals/SelectScenario.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import SearchDatasets from '@/components/Searchbars/SearchDatasets';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';
import Multiselect from 'vue-multiselect';
import TwoLabelsSwitch from '../../components/Elements/TwoLabelsSwitch.vue';

import { ValidationObserver } from 'vee-validate';

export default {
  name: 'DatasetDetail',

  components: {
    AddAnnexe,
    Annexes,
    CustomToast,
    DatasetCard,
    DatasetForm,
    // DatasetSource,
    DeleteDatasetWithResources,
    MainResource,
    Multiselect,
    SearchablePaginatedTable,
    SearchDatasets,
    SearchUsergroups,
    SelectScenario,
    TwoLabelsSwitch,
    ValidationObserver,
  },

  data() {
    return {
      recordTypeBusy: false,
      permissionsBusy: false,
      parentBusy: false,
      loading: false,
      loadingResource: false,
      loadingAnnexe: false,
      reLoadindResource: false,
      disablingResource: false,

      form: {
        display_name: null,
        description: null,
        keywords: [],
        categories: [],
        topics: [],
        kinds: [],
        recommendation: null,
        restriction: null,
        usergroup: null,
        parent: null,
        recordType: null,
        datasetRoles: [],
        licence: null,
        copyright: null,
        granularity: null,
        coverage: null,
        frequence: null,
        publicationDate: null,
        lastUpdateDate: null,
        creationDate: null,
        revisionDate: null,
        year: null,
        lineage: null,
        resource: {
          format: null,
          displayName: null,
          description: null,
          file: null
        },
        thumbnail: null
      },
      newResource: null,
      modifyResource: null,
      updateResource: null,
      isAddingAnnexe: false,

      // permissions
      permissionLevels: [
        {
          label: 'Donnée privée',
          code: 0
        },
        {
          label: 'Recherche',
          code: 1
        },
        {
          label: 'Consultation',
          code: 2
        },
        {
          label: 'Téléchargement',
          code: 3
        }
      ],
      permissionLevel: null,
      permissionsLevelToChange: null,

      // delete
      isDeleteDatasetWithResourceOpen: false,
      datasetWithResourceModalData: {
        name: null,
        nbResources: null
      },

      // scenario
      selectingScenario: false,
      selectingScenarioContext: null,
      possibleScenarios: [],
      selectedScenario: null,

      //task toast
      lastTasks: null,
      currentTasks: [],
      hiddenTasksIds: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'currentDatasetRoles',
      'datasetsCategories',
      'datasetsTopics',
      'datasetsRestrictions',
      'datasetsKinds',
      'datasetsUpdateFrequencies',
      'datasetsRecordTypes'
    ]),
    ...mapState('organisations', [
      'organisationsList'
    ]),
    ...mapState('resources', [
      'resourceInspectionData',
      'currentResourceScenarios'
    ]),
    ...mapState('form', [
      'originalForm'
    ]),
    ...mapState('dashboard', [
      'dashboardTasksQueue'
    ]),
    ...mapGetters('datasets', [
      'currentDatasetPublishState',
      'currentDatasetPublishScenario',
      'currentDatasetUnpublishScenario',
      'currentDatasetExtras'
    ]),

    /** specific components */
    specificComponents() {
      if (this.$config.components && Object.keys(this.$config.components).length) {
        return Object.entries(this.$config.components).map(el => {
          return {
            component: () => import(`../../../public/specific/components/${el[0]}.vue`),
            anchor: el[1]
          };
        });
      }
      return [];
    },
    topAnchoredSpecificComponents() {
      return this.specificComponents.filter(el => el.anchor === 'dataset-detail-top-anchor');
    },
    bottomAnchoredSpecificComponents() {
      return this.specificComponents.filter(el => el.anchor === 'dataset-detail-bottom-anchor');
    },
    /** */

    buildDatasetIndexScenarioId() {
      if (
        this.currentDataset &&
        this.currentDatasetPublishScenario &&
        this.currentDatasetPublishScenario.id &&
        this.currentDatasetUnpublishScenario &&
        this.currentDatasetUnpublishScenario.id
      ) {
        return this.currentDatasetPublishState ?
          this.currentDatasetPublishScenario.id :
          this.currentDatasetUnpublishScenario.id;
      }
      return null;
    },
    childrenFields() {
      return [
        {
          key: this.$t('datasetDetail.childrenTable.fields.name'),
          apiName: 'display_name',
          sortable: false,
          width: '40',
          centered: false
        },
        {
          key: 'link',
          apiName: 'codename',
          sortable: false,
          width: '60',
          centered: false
        },
      ];
    },
    childrenRows() {
      if (this.currentDataset.children && this.currentDataset.children.length) {
        return this.currentDataset.children.map(el => {
          return {
            id: el.id,
            [this.$t('datasetDetail.childrenTable.fields.name')]: el.display_name,
            link: el.codename
          };
        });
      } else {
        return [];
      }
    },

    datasetMainResource() {
      return this.currentDataset.resource_main;
    },
    annexes() {
      return this.currentDataset.resource_annexes ? this.currentDataset.resource_annexes : [];
    },

    canLoadSource() {
      return (
        !this.newResource ||
        (
          this.newResource &&
          !(
            this.newResource.displayName ||
            // this.newResource.description ||
            // this.newResource.kind ||
            // this.newResource.lastUpdateDate ||
            this.newResource.format
          )
        )
      );
    },

    isResourceProcessingPending() {
      return this.currentDataset.resource_main &&
        this.currentDataset.resource_main._processing &&
        this.currentDataset.resource_main._processing.state === 'PENDING';
    }
  },

  async created() {
    // Clean stored form
    this.SET_ORIGINAL_FORM({
      id: null,
      form: null
    });
    this.SET_CURRENT_FORM({
      id: null,
      form: null
    });
    this.SET_MAIN_RESOURCE(null);

    this.loading = true;

    await this.GET_DATASET_DETAIL(this.$route.params.id);
    await this.GET_CURRENT_DATASET_ROLES(this.$route.params.id);

    if (this.currentDataset && this.currentDataset.id) {
      if (this.currentDataset.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(this.currentDataset.usergroup);
      }

      if (this.currentDataset.resource_main && this.currentDataset.resource_main._processing) {
        this.SET_RELOAD_INTERVAL_ID(
          {
            name: 'resource_reloading',
            interval: setInterval(() => {
              this.checkResourceCreation();
            },
            process.env.VUE_APP_RELOAD_INTERVAL)
          }
        );
      }
      // Toasts
      this.getLastDatasetTasks();
      this.SET_RELOAD_INTERVAL_ID(
        {
          name: 'dataset_tasks',
          interval: setInterval(() => {
            this.getLastDatasetTasks();
          },
          process.env.VUE_APP_RELOAD_INTERVAL)
        }
      );

      this.initForm();
    } else {
      this.loading = false;
    }

    if (this.organisationsList.length === 0) {
      this.GET_ORGANISATIONS_LIST({
        direction: null,
        field: null,
        page: 1
      });
    }
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isDeleteDatasetWithResourceOpen = false;
    });
  },

  beforeRouteLeave(to, from, next) {
    this.SET_CURRENT_DATASET({});
    this.CLEAR_RELOAD_INTERVAL_ID('dataset_tasks');
    this.hiddenTasksIds.splice(0, this.hiddenTasksIds.length);
    next();
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
      'CLEAR_RELOAD_INTERVAL_ID'
    ]),
    ...mapMutations('form', [
      'SET_CURRENT_FORM',
      'SET_ORIGINAL_FORM'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapMutations('modal', [
      'SET_MODAL_STATE'
    ]),
    ...mapMutations('resources', [
      'SET_MAIN_RESOURCE',
      'SET_RESOURCES_FILTERS',
      'REMOVE_RESOURCES_FILTERS',
      'SET_RESOURCE_INSPECTION_DATA'
    ]),
    ...mapMutations('datasets', [
      'SET_DUPLICATED_DATASET',
      'SET_DUPLICATED_DATASET_ROLES',
      'SET_CURRENT_DATASET'
    ]),
    ...mapActions('datasets', [
      'GET_DATASET_STATIC_DATA',
      'GET_DATASET_DETAIL',
      'GET_CURRENT_DATASET_ROLES',
      'GET_DATASETS_LIST',
      'UPDATE_DATASET',
      'PATCH_DATASET',
      'DELETE_DATASET',
      'UPDATE_CURRENT_DATASET_USERGROUP'
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST'
    ]),
    ...mapActions('resources', [
      'GET_MAIN_RESOURCE',
      'DELETE_RESOURCE_FILE',
      'START_CREATE_RESOURCE',
      'FINISH_CREATE_RESOURCE',
      'START_MODIFY_RESOURCE',
      'FINISH_MODIFY_RESOURCE',
      'START_UPDATE_RESOURCE',
      'FINISH_UPDATE_RESOURCE',
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),
    ...mapActions('dashboard', [
      'GET_DASHBOARD_TASKS'
    ]),
    ...mapActions('usergroups', [
      'GET_CONTACTS_LIST'
    ]),

    setLoader(e) {
      this.loading = e;
    },

    setUsergroup(e) {
      if (e !== this.form.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(e);
      }
      this.form.usergroup = e;
    },

    async getLastDatasetTasks() {
      this.lastTasks = await dashboardAPI.getDatasetLastTasks(this.currentDataset.id);
      if (this.$refs && this.$refs['custom-toast']) {
        for (const toast of this.$refs['custom-toast']) {
          toast.hideToast();
        }
      }
      this.currentTasks.splice(0, this.currentTasks.length);
      this.currentTasks = [...this.lastTasks.filter(el => !this.hiddenTasksIds.includes(el.id))];
      this.currentTasks.sort((a,b) => {
        return new Date(a.stop_date) - new Date(b.stop_date);
      });
      this.currentTasks.forEach(e => e.visible = true);
    },

    hideToast(e) {
      const index = this.currentTasks.findIndex(el => el.id === e);
      const id = this.currentTasks.find(el => el.id === e).id;
      this.currentTasks.splice(index, 1);
      this.hiddenTasksIds.push(id);
    },

    async initForm() {
      this.loading = false;

      await this.GET_DATASET_STATIC_DATA();

      this.form.display_name = this.currentDataset.display_name;
      this.form.thumbnail = this.currentDataset.thumbnail;
      this.form.description = this.currentDataset.description;
      this.form.usergroup = this.currentDataset.usergroup;
      this.form.parent = this.currentDataset.parent;
      if (this.currentDataset.record_type && this.currentDataset.record_type.value) {
        this.form.recordType = {
          choice: this.currentDataset.record_type.value,
          label: this.currentDataset.record_type.label
        };
      }
      this.form.categories =
        this.currentDataset.categories ? this.currentDataset.categories.map(el => el.display_name) : null;
      this.form.topics = this.currentDataset.topics.map(el => el.display_name);
      this.form.kinds = this.currentDataset.kinds.map(el => el.id);
      this.form.licence = this.currentDataset.license;
      this.form.copyright = this.currentDataset.copyright;
      this.form.granularity = this.currentDataset.granularity ? this.currentDataset.granularity : '';
      this.form.coverage =
        COVERAGE_OPTIONS.find(el => el.code === this.currentDataset.geographical_coverage) ?
          COVERAGE_OPTIONS.find(el => el.code === this.currentDataset.geographical_coverage) : '';
      this.form.keywords = this.currentDataset.tags;
      this.form.recommendation = this.currentDataset.recommendation;
      this.form.restriction = this.currentDataset.restriction ?
        this.datasetsRestrictions.find(el => el === this.currentDataset.restriction) ?
          this.datasetsRestrictions.find(el => el === this.currentDataset.restriction) :
          this.currentDataset.restriction :
        null;
      this.form.publicationDate = this.currentDataset.publication_date ? this.currentDataset.publication_date.slice(0, 10) : '';
      this.form.lastUpdateDate = this.currentDataset.last_update_date ? this.currentDataset.last_update_date.slice(0, 10) : '';
      this.form.creationDate = this.currentDataset.creation_date ? this.currentDataset.creation_date : null;
      this.form.revisionDate =
        this.currentDataset.last_revision_date ? this.currentDataset.last_revision_date : null;
      this.form.frequence =
        this.currentDataset.update_frequency ?
          this.datasetsUpdateFrequencies.find(el => el.value === this.currentDataset.update_frequency) :
          null,
      this.form.year = this.currentDataset.year;
      this.form.lineage = this.currentDataset.lineage;

      // Roles
      let order = 1;
      this.form.datasetRoles.splice(0);
      for (let role of this.currentDatasetRoles) {
        try {
          role.order = order;
          const contact = await datasetsAPI.getContact(role.contact.id);
          role.contact = contact;
          if (contact.usergroup && contact.usergroup.id) {
            role.contact.usergroup = JSON.parse(JSON.stringify(contact.usergroup.id));
          } else {
            role.contact.usergroup = null;
          }
          this.form.datasetRoles.push(role);
          order += 1;
        } catch (err) {
          console.error(err);
          continue;
        }
      }

      // extras
      if (this.currentDatasetExtras) {
        for (const extra of this.currentDatasetExtras) {
          if (extra.value) {
            this.form[extra.key] = extra.value;
          }
        }
      }
      this.permissionLevel =
        this.permissionLevels.find(el => el.code === this.currentDataset.default_permission_level);
      this.SET_ORIGINAL_FORM({
        id: 'datasetDetail',
        form: this.form
      });

      this.loading = false;
    },

    setForm(e) {
      this.form = e;
      this.SET_CURRENT_FORM({
        id: 'datasetDetail',
        form: e
      });
    },
    setThumbnail(e) {
      this.form.thumbnail = e;
    },

    async removeContact(index) {
      const removeContactFromDataset = async () => {
        try {
          this.loading = true;
          const roleId = this.form.datasetRoles[index].id;
          await datasetsAPI.removeDatasetRole(this.currentDataset.id, roleId);
          if (this.buildDatasetIndexScenarioId) {
            await datasetsAPI.buildDatasetIndex(this.currentDataset.id, this.buildDatasetIndexScenarioId);
          }
          this.form.datasetRoles.splice(index, 1);
          this.form.datasetRoles.forEach((el, i) => el.order = i + 1);
          await this.GET_CONTACTS_LIST({});
          await this.GET_CURRENT_DATASET_ROLES(this.currentDataset.id);
          this.$refs.datasetForm.checkIfMainContactExist();
          this.loading = false;
        } catch (err) {
          console.error(err);
          this.loading = false;
        }
      };

      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.removeContactFromDataset.title'),
        content: this.$t('modals.removeContactFromDataset.content'),
        trigger: removeContactFromDataset
      });
    },

    async afterResourceChange() {
      this.loading = true;
      this.isAddingAnnexe = false;
      this.newResource = null;
      if (this.buildDatasetIndexScenarioId) {
        await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
      }
      this.GET_DATASET_DETAIL(this.$route.params.id)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setParentDataset(e) {
      if ((e && this.form.parent !== e.id) || (!e && this.form.parent)) {
        this.form.parent = e ? e.id : null;
        this.updateParentDataset();
      }
    },

    setDatasetRecordType(e) {
      this.form.recordType = e ? e : null;
      this.updateDatasetRecordType();
    },

    askForChangePermissionLevel(e) {
      this.permissionsLevelToChange = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.changeDatasetPermissions.title'),
        content: this.$t('modals.changeDatasetPermissions.content'),
        trigger: this.changePermissionLevel
      });
    },

    changePermissionLevel() {
      const data = {
        default_permission_level: this.permissionsLevelToChange.code
      };
      this.permissionsBusy = true;
      this.PATCH_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(() => {
          this.permissionsLevelToChange = null;
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              this.permissionsBusy = false;
            })
            .catch(() => {
              this.permissionsBusy = false;
            });
        })
        .catch(() => {
          this.permissionsLevelToChange = null;
          this.permissionsBusy = false;
        });
    },

    updateParentDataset() {
      const data = {
        parent: this.form.parent
      };
      this.parentBusy = true;
      this.PATCH_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(async () => {
          if (this.buildDatasetIndexScenarioId) {
            await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
          }
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              this.parentBusy = false;
            })
            .catch(() => {
              this.parentBusy = false;
            });
        })
        .catch(() => {
          this.parentBusy = false;
        });
    },

    updateDatasetRecordType() {
      const data = {
        record_type_value: this.form.recordType ? this.form.recordType.choice : null
      };
      this.recordTypeBusy = true;
      this.PATCH_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(async () => {
          if (this.buildDatasetIndexScenarioId) {
            await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
          }
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              this.recordTypeBusy = false;
            })
            .catch(() => {
              this.recordTypeBusy = false;
            });
        })
        .catch(() => {
          this.recordTypeBusy = false;
        });
    },

    duplicateDataset() {
      this.loading = true;
      this.SET_DUPLICATED_DATASET(this.currentDataset);
      this.SET_DUPLICATED_DATASET_ROLES(this.currentDatasetRoles);
      this.$router.push({
        name: 'AddDataset'
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async runDatasetPublishScenario(scenarioId) {
      try {
        this.loading = true;
        await datasetsAPI.buildDatasetIndex(this.currentDataset.id, scenarioId);
        this.getLastDatasetTasks();
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },

    async saveDataset() {
      this.$root.$emit('updateResourceInfos');
      // if (this.$refs.resourceComponent) {
      //   this.$refs.resourceComponent.updateResourceInfos(true);
      // }
      // if (this.$refs.annexComponent && this.$refs.annexComponent.length) {
      //   for (const annexComponent of this.$refs.annexComponent) {
      //     annexComponent.updateResourceInfos(true);
      //   }
      // }

      const data = {
        ...this.currentDataset,
        ...this.form.display_name && { display_name: this.form.display_name },
        ...this.form.description && { description: this.form.description },
        ...this.form.usergroup.id && { usergroup: this.form.usergroup.id },
        parent: this.form.parent,
        record_type: this.form.recordType ? this.form.recordType.choice : null,
        // eslint-disable-next-line no-unused-vars
        contact_roles: this.form.datasetRoles.map(({ order, ...el }) => {
          el.contact.usergroup_id = el.contact.usergroup;
          delete el.contact.usergroup;
          return el;
        }),
        categories: this.datasetsCategories.filter(el => this.form.categories.includes(el.display_name)),
        topics: this.datasetsTopics.filter(el => this.form.topics.includes(el.display_name)),
        kinds: this.datasetsKinds.filter(el => this.form.kinds.includes(el.id)),
        tags: this.form.keywords,
        recommendation: this.form.recommendation ? this.form.recommendation : null,
        restriction: this.form.restriction,
        license: this.form.licence,
        copyright: this.form.copyright ? this.form.copyright : null,
        granularity: this.form.granularity ? this.form.granularity : null,
        geographical_coverage: this.form.coverage ? this.form.coverage.code : null,
        publication_date: this.form.publicationDate ?
          new Date(this.form.publicationDate).toISOString().split('T')[0] : null,
        last_update_date: this.form.lastUpdateDate ?
          new Date(this.form.lastUpdateDate).toISOString().split('T')[0] : null,
        year: this.form.year,
        lineage: this.form.lineage ? this.form.lineage : null,
        update_frequency: this.form.frequence ? this.form.frequence.value : null,
      };

      // Add extras
      if (
        this.$config.forms.dataset.extraFields &&
        this.$config.forms.dataset.extraFields.length
      ) {
        const extras = [];
        for (const extraField of this.$config.forms.dataset.extraFields) {
          for (const field of extraField.fields) {
            extras.push({
              key: field.key,
              value: this.form[field.key] && this.form[field.key].length ? this.form[field.key] : null
            });
          }
        }
        data.extras = extras;
      }

      // publish value can't be null, force true if null
      if (this.currentDataset.publish === null) {
        data.publish = true;
      }
      this.loading = true;

      if (this.form.thumbnail instanceof FormData) {
        await datasetsAPI.setDatasetThumbnail(this.currentDataset.id, this.form.thumbnail);
      } else if (this.currentDataset.thumbnail && this.form.thumbnail === null) {
        await datasetsAPI.removeDatasetThumbnail(this.currentDataset.id);
      }

      // eslint-disable-next-line no-unused-vars
      const contactRoles = this.form.datasetRoles.map(({ order, ...el }) => { return el; });

      // Create new contacts
      for (let [i, role] of contactRoles.entries()) {
        if (!role.contact.id) {
          try {
            const newContact = await datasetsAPI.createContact(role.contact);
            contactRoles[i].contact.id = newContact.id;
          } catch (err) {
            console.error(err);
          }
        }
      }

      // Check for changed contacts
      for (let role of contactRoles) {
        const originalContact = this.currentDatasetRoles.find(el => el.contact.id === role.contact.id);
        if (originalContact) {
          if (!this.lookForChangesInContact(originalContact.contact, role.contact)) {
            try {
              await datasetsAPI.updateContact(role.contact.id, role.contact);
            } catch (err) {
              ErrorService.onError(err, undefined, `${this.$t('messages.error.contact.update')} ${role.contact.name}`);
            }
          }
        }
      }

      // Check for changed roles
      for (const role of contactRoles) {
        const originalContact = this.currentDatasetRoles.find(el => el.contact.id === role.contact.id);
        if (originalContact && ((originalContact.role !== role.role) || (originalContact.main !== role.main))) {
          let force = false;
          if (originalContact.main !== role.main) {
            force = true;
          }
          await datasetsAPI.updateDatasetRole(
            this.currentDataset.id,
            {
              role: role.role,
              contact_id: role.contact.id,
              main: role.main
            },
            role.id,
            force
          );
        }
      }

      // Check for created roles
      for (const role of contactRoles) {
        if (!role.id || (role.id && !(this.currentDatasetRoles.map(el => el.id).includes(role.id)))) {
          try {
            await datasetsAPI.createDatasetRole(this.currentDataset.id, {
              role: role.role,
              contact_id: role.contact.id,
              main: role.main
            });
          } catch (err) {
            console.error(err);
          }
        }
      }

      // Check for removed roles
      for (const role of this.currentDatasetRoles) {
        if (!(contactRoles.map(el => el.id).includes(role.id))) {
          await datasetsAPI.removeDatasetRole(this.currentDataset.id, role.id);
        }
      }

      this.UPDATE_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(async () => {
          if (this.currentDataset && this.currentDataset.id) {
            await this.GET_DATASET_DETAIL(this.currentDataset.id);
            await this.GET_CURRENT_DATASET_ROLES(this.currentDataset.id);
            if (this.currentDataset && this.currentDataset.id) {
              this.initForm();
              // await datasetsAPI.buildDatasetIndex(this.currentDataset.id, this.buildDatasetIndexScenarioId);
              this.getLastDatasetTasks();
            }
          }
          this.GET_DATASETS_LIST({
            direction: null,
            field: null
          });
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    askToDeleteDataset() {
      if (!this.currentDataset.resource_main && !this.currentDataset.resource_annexes.length) {
        this.OPEN_MODAL({
          modal: 'confirmation',
          open: true,
          title: this.$t('modals.deleteDataset.title'),
          content: this.$t('modals.deleteDataset.content.dataset', { dataset: this.currentDataset.display_name }),
          trigger: this.deleteDataset
        });
      } else {
        this.datasetWithResourceModalData.name = this.currentDataset.display_name;
        this.datasetWithResourceModalData.nbResources =
          this.currentDataset.resource_annexes.length + (this.currentDataset.resource_main ? 1 : 0);
        this.isDeleteDatasetWithResourceOpen = true;
      }
    },

    deleteDataset() {
      this.loading = true;
      this.DELETE_DATASET(this.currentDataset)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: 'Datasets' });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async deleteDatasetWithResources() {
      this.isDeleteDatasetWithResourceOpen = false;
      this.loading = true;

      // Delete annexes
      if (this.currentDataset.resource_annexes && this.currentDataset.resource_annexes.length) {
        for (const annex of this.currentDataset.resource_annexes) {
          try {
            await this.DELETE_RESOURCE_FROM_DATASET({
              resourceToDatasetId: annex.resourcedataset_id,
              resource: annex
            });
            await this.DELETE_RESOURCE(annex);
          } catch (err) {
            console.error(err);
            this.loading = false;
          }
        }
      }
      // Delete main resource
      if (this.currentDataset.resource_main) {
        try {
          await this.DELETE_RESOURCE_FROM_DATASET({
            resourceToDatasetId: this.currentDataset.resource_main.resourcedataset_id,
            resource: this.currentDataset.resource_main
          });
          await this.DELETE_RESOURCE(this.currentDataset.resource_main);
        } catch (err) {
          console.error(err);
          this.loading = false;
        }
      }
      // Finally delete dataset
      this.DELETE_DATASET(this.currentDataset)
        .then(() => {
          this.loading = false;
          this.$router.push({ name: 'Datasets' });
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setResource(e) {
      this.newResource = e;
    },

    startModifyResource(e) {
      this.modifyResource = e;
      let datastoreType = null;
      // let geoserverLayerType = null;
      if (this.modifyResource.resourceType === 'datastore') {
        datastoreType = this.modifyResource.data.format;
      }
      // if (this.modifyResource.resourceType === 'geoserver') {
      //   geoserverLayerType = this.modifyResource.data.layerType;
      // }

      if (e.type === 'resource') {
        this.loadingResource = true;
        this.disablingResource = true;
      } else if (e.type === 'annexe') {
        this.loadingAnnexe = true;
      } else {
        this.loading = true;
      }
      this.START_MODIFY_RESOURCE({
        typeResource: e.resourceType,
        data: e.data,
        datastoreType: datastoreType,
        resourceId: e.resourceId
      })
        .then(() => {
          if (this.currentResourceScenarios.update && this.currentResourceScenarios.update.length) {
            this.possibleScenarios = this.currentResourceScenarios.update;
            this.selectingScenarioContext = 'modify';
            if (this.possibleScenarios.length > 1) {
              this.selectingScenario = true;
            } else {
              this.finishModifyResource(this.possibleScenarios[0].id);
            }
          } else {
            ErrorService.onError(null, 'Pas de scénario ...');
            this.loading = false;
            this.loadingResource = false;
            this.disablingResource = false;
            this.loadingAnnexe = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.loadingResource = false;
          this.disablingResource = false;
          this.loadingAnnexe = false;
        });
    },

    finishModifyResource(action) {
      this.selectingScenario = false;
      const finishResourceUpdateData = {
        kwargs: {
          usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          }
        }
      };

      let datastoreType = null;
      if (this.modifyResource.resourceType === 'datastore') {
        datastoreType = this.modifyResource.data.format;
      }
      this.FINISH_MODIFY_RESOURCE({
        type: this.modifyResource.type,
        typeResource: this.modifyResource.resourceType,
        action: action,
        data: finishResourceUpdateData,
        ...this.modifyResource.resourceType === 'ftp' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'href' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'file-download' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'datastore' && {
          datastoreType: this.modifyResource.data.format,
          datastoreId: this.modifyResource.data.id
        },
        datasetId: this.currentDataset.id,
        resourceId: this.modifyResource.resourceId,
        datastoreType: datastoreType,
        async: true
      })
        .then(() => {
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              if (!this.isResourceProcessingPending) {
                this.disablingResource = false;
              }
              this.modifyResource = null;
              this.loadingResource = false;
              this.loadingAnnexe = false;
              this.loading = false;
            })
            .catch(() => {
              this.modifyResource = null;
              this.loadingResource = false;
              this.loadingAnnexe = false;
              this.loading = false;
            });
          this.afterCreationRequests();
        })
        .catch((err) => {
          console.error(err);
          this.modifyResource = null;
          this.loadingResource = false;
          this.loadingAnnexe = false;
          this.loading = false;
        });
    },

    startUpdateResource(e) {
      this.updateResource = e;
      let datastoreType = null;
      let geoserverLayerType = null;
      if (this.updateResource.resourceType === 'datastore') {
        datastoreType = this.updateResource.data.format;
      }
      if (this.updateResource.resourceType === 'geoserver') {
        geoserverLayerType = this.updateResource.data.layerType;
      }

      if (e.type === 'resource') {
        this.loadingResource = true;
        this.disablingResource = true;
      } else if (e.type === 'annexe') {
        this.loadingAnnexe = true;
      } else {
        this.loading = true;
      }

      this.START_UPDATE_RESOURCE({
        typeResource: e.resourceType,
        data: e.data,
        datastoreType: datastoreType,
        layerType: geoserverLayerType,
      })
        .then(() => {
          if (this.currentResourceScenarios.update && this.currentResourceScenarios.update.length) {
            this.possibleScenarios = this.currentResourceScenarios.update;
            this.selectingScenarioContext = 'update';
            if (this.possibleScenarios.length > 1) {
              this.selectingScenario = true;
            } else {
              this.finishUpdateResource(this.possibleScenarios[0].id);
            }
          } else {
            ErrorService.onError(null, 'Pas de scénario ...');
            this.loading = false;
            this.loadingResource = false;
            this.loadingAnnexe = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.loadingResource = false;
          this.loadingAnnexe = false;
        });
    },

    finishUpdateResource(action) {
      this.selectingScenario = false;

      this.FINISH_UPDATE_RESOURCE({
        type: this.updateResource.type,
        typeResource: this.updateResource.resourceType,
        action: action,
        ...this.updateResource.resourceType === 'datastore' && {
          datastoreType: this.updateResource.data.format,
          datastoreId: this.updateResource.data.id
        },
        kwargs: {
          usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          }
        }
      })
        .then(() => {
          this.GET_DATASET_DETAIL(this.$route.params.id)
            .then(() => {
              if (!this.isResourceProcessingPending) {
                this.disablingResource = false;
              }
              this.updateResource = null;
              this.loadingResource = false;
              this.loadingAnnexe = false;
              this.loading = false;
            })
            .catch(() => {
              this.updateResource = null;
              this.loadingResource = false;
              this.loadingAnnexe = false;
              this.loading = false;
            });
          this.afterCreationRequests();
        })
        .catch((err) => {
          console.error(err);
          this.updateResource = null;
          this.loadingResource = false;
          this.loadingAnnexe = false;
          this.loading = false;
        });
    },

    startCreateResource() {
      try {
        this.loadingResource = true;
        this.disablingResource = true;

        let startResourceCreationData;
        // let scheduler;
        let datastoreType = null;

        if (this.newResource.type === 'datastore') {
          const { format, ...datastoreData } = this.newResource;
          startResourceCreationData = {
            namespace: datastoreData.namespace,
            schema_name: datastoreData.schema_name,
            table_name: datastoreData.table_name,
          };
          datastoreType = format;
        } else {
          const { format, ...resourceForm } = this.newResource;
          startResourceCreationData = {
            ...resourceForm,
            organisation: this.currentDataset.usergroup.id,
            ...this.newResource.type === 'file-upload' && {
              dataformat_id: format.id,
            },
            ...this.newResource.type === 'ftp' && {
              dataformat_id: format.id,
              href: this.newResource.selectedFtpFiles.map(el => el.value),
            },
            ...this.newResource.type === 'href' && {
              dataformat_id: format.id,
              href: this.newResource.href,
            },
            ...this.newResource.type === 'file-download' && {
              dataformat_id: format.id,
              href: this.newResource.href,
            },
            ...this.newResource.type === 'geoserver' && {
              type: this.newResource.layerType
            }
          };
        }

        // if (['ftp', 'href', 'file-download'].includes(this.newResource.type)) {
        //   scheduler = this.newResource.scheduler;
        // }
        this.START_CREATE_RESOURCE({
          typeResource: this.newResource.type,
          data: startResourceCreationData,
          datastoreType: datastoreType
        })
          .then(() => {
            if (this.currentResourceScenarios.create && this.currentResourceScenarios.create.length) {
              this.possibleScenarios = this.currentResourceScenarios.create;
              this.selectingScenarioContext = 'create';
              if (this.possibleScenarios.length > 1) {
                this.selectingScenario = true;
              } else {
                this.finishCreateResource(this.possibleScenarios[0].id/*, scheduler*/);
              }
            } else {
              ErrorService.onError(null, this.$t('messages.error.resource.creation.noScenario'));
              console.error('No scénarios, can\'t create resource ...');
              this.loadingResource = false;
            }
          })
          .catch((err) => {
            console.error(err);
            this.loadingResource = false;
          });
      } catch (err) {
        console.error(err);
        this.loadingResource = false;
      }
    },

    finishCreateResource(action/*, scheduler*/) {
      this.selectingScenario = false;
      const finishResourceCreationData = {
        kwargs: {
          resource__display_name: this.newResource.displayName,
          ...this.newResource.geographiclayerDisplayName && {
            geographiclayer__display_name: this.newResource.geographiclayerDisplayName
          },
          ...this.newResource.geographiclayerCodename && {
            geographiclayer__codename: this.newResource.geographiclayerCodename
          },
          resource__description: this.newResource.description,
          resource__kind_id: this.newResource.kind && this.newResource.kind.id ? this.newResource.kind.id : null,
          resource__last_update_date: this.newResource.lastUpdateDate,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          }
        }
      };

      this.FINISH_CREATE_RESOURCE({
        typeResource: this.newResource.type,
        action: action,
        data: finishResourceCreationData,
        // scheduler: scheduler,
        ...this.newResource.type === 'datastore' && { datastoreType: this.newResource.format },
        ...this.newResource.type === 'geoserver' && { layerType: this.newResource.layerType },
        async: true
      })
        .then(() => {
          setTimeout(() => {
            this.GET_DATASET_DETAIL(this.$route.params.id)
              .then(() => {
                if (this.currentDataset.resource_main) {
                  if (!this.isResourceProcessingPending) {
                    this.disablingResource = false;
                  }
                  if (this.currentDataset.resource_main.id) {
                    this.GET_MAIN_RESOURCE({
                      id: this.currentDataset.resource_main.id,
                      resourceToDatasetId: this.currentDataset.resource_main.resourcedataset_id
                    })
                      .then(() => {
                        this.loadingResource = false;
                        this.loadingAnnexe = false;
                        this.loading = false;
                      })
                      .catch((err) => {
                        console.error(err);
                        this.loadingResource = false;
                        this.loadingAnnexe = false;
                        this.loading = false;
                      });
                  } else {
                    this.loadingResource = false;
                    this.loadingAnnexe = false;
                    this.loading = false;
                  }
                }
              })
              .catch((err) => {
                console.error(err);
                this.loadingResource = false;
                this.loadingAnnexe = false;
                this.loading = false;
              });
            this.afterCreationRequests();
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          this.loadingResource = false;
        });
    },

    // Function to check if resource creation is still pending
    checkResourceCreation() {
      if (this.isResourceProcessingPending) {
        this.reLoadindResource = true;
        this.GET_DATASET_DETAIL(this.$route.params.id)
          .then(() => {
            if (!this.isResourceProcessingPending) {
              this.disablingResource = false;
            }
            if (this.currentDataset.resource_main.id) {
              this.GET_MAIN_RESOURCE({
                id: this.currentDataset.resource_main.id,
                resourceToDatasetId: this.currentDataset.resource_main.resourcedataset_id
              })
                .then(() => {
                  this.reLoadindResource = false;
                })
                .catch(() => {
                  this.reLoadindResource = false;
                });
            } else {
              this.reLoadindResource = false;
            }
          })
          .catch(() => {
            this.reLoadindResource = false;
          });
      } else {
        this.disablingResource = false;
        this.CLEAR_RELOAD_INTERVAL_ID('resource_reloading');
      }
    },

    // Fired after each resource creation
    afterCreationRequests() {
      this.SET_CURRENT_FORM({
        id: null,
        form: null
      });
      this.GET_DASHBOARD_TASKS({
        direction: null,
        field: null
      });
      this.loadingResource = false;
      // Fetch data periodically to retrieve resource creation status
      this.SET_RELOAD_INTERVAL_ID(
        {
          name: 'resource_reloading',
          interval: setInterval(() => {
            this.checkResourceCreation();
          },
          process.env.VUE_APP_RELOAD_INTERVAL)
        }
      );
    },

    lookForChangesInContact(original, current) {
      return (
        original.name === current.name &&
        original.email === current.email &&
        original.position === current.position &&
        original.phone_number === current.phone_number &&
        original.usergroup === current.usergroup_id
      );
    }
  }
};
</script>
