<template>
  <b-modal
    id="add-user-modal"
    v-model="openModal"
    size="lg"
    title="Mise à jour de la base de donnée"
  >
    <DatastoreCreator
      :format="format"
      @set="setResource"
    />
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="cancel"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="updateResource"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import DatastoreCreator from '@/components/Resource/creators/DatastoreCreator.vue';

export default {
  name: 'UpdateDatastoreModal',

  components: {
    DatastoreCreator
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      openModal: false,
      newResource: null
    };
  },

  watch: {
    open: function(newValue) {
      this.openModal = newValue;
    },
  },

  methods: {
    cancel() {
      this.$emit('hide');
    },

    setResource(e) {
      this.newResource = e;
    },

    updateResource() {
      this.$emit('update', this.newResource);
      this.$emit('hide');
    }
  }
};
</script>
