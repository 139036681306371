<template>
  <div>
    <b-overlay
      no-wrap
      :show="isLoading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    />
    <div
      v-for="annexResource of annexResources"
      :key="annexResource.id"
      class="annex_resource"
    >
      <ResourceEditor
        v-if="
          annexResourcesDatasetRelation &&
            annexResourcesDatasetRelation.length &&
            annexResourcesDatasetRelation[0].id
        "
        :key="`annex-${annexResource.codename}`"
        :meta-resource="annexResource"
        :resource="annexesData.find(el => el.id === annexResource.rel[0].id)"
        :is-main="false"
        v-on="$listeners"
      />
      <div
        v-if="annexesData.length && annexesData.find(el => el.id === annexResource.rel[0].id)"
        class="resource__delete"
      >
        <div />
        <b-button
          variant="danger"
          @click="openResourceDeleteModal(annexResource)"
        >
          {{ $t(`buttons.delete`) }}
        </b-button>
      </div>
    </div>
    <DeleteResourceModal
      :open="isDeleteResourceModalOpen"
      :resource="resourceToDelete"
      @hide="isDeleteResourceModalOpen = false"
      @delete="deleteResource(resourceToDelete.id)"
    />
  </div>
</template>

<script>
import resourcesAPI from '@/api/resourcesAPI.js';
import { mapState, mapMutations, mapActions } from 'vuex';

import DeleteResourceModal from '@/components/Resource/modals/DeleteResourceModal.vue';
import ResourceEditor from '@/components/Resource/ResourceEditor.vue';

export default {
  name: 'Annexes',

  components: {
    DeleteResourceModal,
    ResourceEditor
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    annexes: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isLoading: false,
      isDeleteResourceModalOpen: false,
      resourceToDelete: null,
      annexesData: []
    };
  },

  computed: {
    ...mapState('resources', [
      'annexResources',
      'annexResourcesDatasetRelation'
    ]),
    ...mapState('datasets', [
      'currentDataset'
    ])
  },

  watch: {
    loading(newValue) {
      this.isLoading = newValue;
    },

    annexes: {
      deep: true,
      async handler() {
        await this.getAnnexesData();
      }
    }
  },

  async created() {
    await this.getAnnexesData();
  },

  methods: {
    ...mapMutations('resources', [
      'SET_ANNEX_RESOURCE'
    ]),
    ...mapActions('resources', [
      'GET_ANNEX_RESOURCE',
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    async getAnnexesData() {
      try {
        this.SET_ANNEX_RESOURCE(null);
        if (this.annexes && this.annexes.length) {
          this.isLoading = true;
          for (const annex of this.annexes) {
            await this.GET_ANNEX_RESOURCE({
              id: annex.id,
              resourceToDatasetId: annex.resourcedataset_id
            });
          }
          if (this.annexResources && this.annexResources.length) {
            this.annexesData.splice(0, this.annexesData.length);
            for (const annexResource of this.annexResources) {
              if (annexResource.rel && annexResource.rel.length && annexResource.rel[0]['@uri']) {
                const source = await resourcesAPI.getResourceData(annexResource.rel[0]['@uri']);
                const index = this.annexesData.findIndex(el => el.id === source.id);
                if (index === -1) {
                  this.annexesData.push(source);
                }
              }
            }
          }
          this.isLoading = false;
        }
      } catch (err) {
        console.error(err);
        this.isLoading = false;
      }
    },

    openResourceDeleteModal(resource) {
      this.resourceToDelete = resource;
      this.isDeleteResourceModalOpen = true;
    },

    deleteResource(id) {
      const annex = this.annexes.find(el => el.id === id);
      this.isLoading = true;
      this.DELETE_RESOURCE_FROM_DATASET({
        resourceToDatasetId: annex.resourcedataset_id,
        resource: annex
      })
        .then(() => {
          const annexResource = this.annexResources.find(el => el.id === id);
          this.DELETE_RESOURCE(annexResource)
            .then(() => {
              this.SET_ANNEX_RESOURCE(null);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
          this.$emit('delete');
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    }
  }
};
</script>
