<template>
  <div class="main_resource">
    <b-overlay
      no-wrap
      :show="isLoading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      style="z-index: 1050;"
    />
    <div v-if="mainResource && mainResourceData && mainResourceDatasetRelation">
      <ResourceEditor
        :meta-resource="mainResource"
        :resource="mainResourceData"
        v-on="$listeners"
        @get="getMainResource"
        @delete="isDeleteResourceModalOpen = true"
      />
      <div class="resource__delete">
        <div />
        <b-button
          variant="danger"
          @click="isDeleteResourceModalOpen = true"
        >
          {{ $t(`buttons.delete`) }}
        </b-button>
      </div>
    </div>
    <div
      v-if="datasetMainResource && datasetMainResource._processing"
      :style="{ width: '100%' }"
    >
      <div>
        <b-overlay
          :show="reLoading"
          :variant="'white'"
          :opacity="0.7"
          :blur="'2px'"
          rounded="sm"
        >
          <ResourceCreator
            v-if="
              ((!datasetMainResource || (datasetMainResource && !datasetMainResource.id)) && !mainResource) &&
                datasetMainResource._processing.state === 'FAILURE'
            "
            v-on="$listeners"
          />
          <div
            style="margin-left: 0;"
            :class="datasetMainResource._processing.state === 'FAILURE' ? 'alert-danger' : 'alert-info'"
            class="alert alert-resource"
            role="alert"
          >
            <p style="font-size: 0.9rem;">
              <span v-if="datasetMainResource._processing.state === 'FAILURE'">
                {{ $t('datasetDetail.mainResource.messages.error') }}
              </span>
              <span v-else>
                {{ $t('datasetDetail.mainResource.messages.pending') }}
              </span>
              <router-link
                :to="{ name: 'TaskDetail', params: { id: datasetMainResource._processing.id }}"
              >
                {{ $t('words.here') }}
              </router-link>.
            </p>
          </div>
        </b-overlay>
      </div>
    </div>
    <div
      v-else-if="!datasetMainResource && !mainResource"
    >
      <p class="infos">
        {{ $t('datasetDetail.mainResource.empty') }}
      </p>
      <ResourceCreator
        :required="false"
        v-on="$listeners"
      />
    </div>
    <DeleteResourceModal
      :open="isDeleteResourceModalOpen"
      :resource="mainResourceData"
      @hide="isDeleteResourceModalOpen = false"
      @delete="deleteResource"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import DeleteResourceModal from '@/components/Resource/modals/DeleteResourceModal.vue';
import ResourceCreator from '@/components/Resource/ResourceCreator.vue';
import ResourceEditor from '@/components/Resource/ResourceEditor.vue';

export default {
  name: 'MainResource',

  components: {
    DeleteResourceModal,
    ResourceCreator,
    ResourceEditor
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    reLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  
  data() {
    return {
      isLoading: false,
      resourceEditorKey: 1,
      isDeleteResourceModalOpen: false
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
    ]),
    ...mapState('resources', [
      'mainResource',
      'mainResourceData',
      'mainResourceDatasetRelation'
    ]),
    ...mapGetters('resources', [
      'mainResourceRoot',
    ]),

    datasetMainResource() {
      return this.currentDataset.resource_main;
    },
  },

  watch: {
    loading(newValue) {
      this.isLoading = newValue;
    },
    disabled() {
      this.resourceEditorKey += 1;
    },
    datasetMainResource: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.getMainResource();
        }
      }
    }
  },

  async created() {
    if (this.datasetMainResource && this.datasetMainResource.id) {
      await this.getMainResource();
    } else if (!this.datasetMainResource) {
      this.SET_MAIN_RESOURCE(null);
    }
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isDeleteResourceModalOpen = false;
    });
  },

  methods: {
    ...mapMutations('resources', [
      'SET_MAIN_RESOURCE'
    ]),
    ...mapActions('resources', [
      'GET_MAIN_RESOURCE',
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    async getMainResource() {
      try {
        this.isLoading = true;
        await this.GET_MAIN_RESOURCE({
          id: this.datasetMainResource.id,
          resourceToDatasetId: this.datasetMainResource.resourcedataset_id
        });
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.isLoading = false;
      }
    },

    deleteResource() {
      this.isLoading = true;
      this.DELETE_RESOURCE_FROM_DATASET({
        resourceToDatasetId: this.datasetMainResource.resourcedataset_id,
        resource: this.datasetMainResource
      })
        .then(() => {
          this.DELETE_RESOURCE(this.datasetMainResource)
            .then(() => {
              this.SET_MAIN_RESOURCE(null);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
          this.$emit('delete');
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.alert-resource {
  margin: 1rem -1px 0 -1px !important;
}
</style>
