<template>
  <b-modal
    id="add-user-modal"
    v-model="openModal"
    size="lg"
    title="Mise à jour du fichier"
  >
    <FTPCreator
      @set="setResource"
    />
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-secondary"
          class="float-left"
          @click="cancel"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="float-right"
          @click="updateFileUploadFile"
        >
          {{ $t('buttons.confirm') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import FTPCreator from '@/components/Resource/creators/FTPCreator.vue';

export default {
  name: 'UpdateFTPResourceModal',

  components: {
    FTPCreator
  },

  props: {
    open: {
      type: Boolean,
      default: false
    },
    format: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },

  data() {
    return {
      openModal: false,
      newResource: null
    };
  },

  watch: {
    open: function(newValue) {
      this.openModal = newValue;
    }
  },

  methods: {
    cancel() {
      this.$emit('hide');
    },

    setResource(e) {
      this.newResource = e;
    },

    updateFileUploadFile() {
      this.$emit('update', this.newResource);
      this.$emit('close');
    }
  }
};
</script>

<style lang="less" scoped>

h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

p {
  margin-left: 1em;
  font-size: 1.1em;
  font-weight: 500;
}

</style>
