<template>
  <div class="resource card">
    <b-overlay
      :show="loading"
      :variant="'white'"
      :opacity="0.9"
      :blur="'2px'"
      style="z-index: 1040;"
    >
      <div class="card-header">
        <div class="header-top">
          <div class="resource_icon">
            <i class="bi bi-layers-fill" />
          </div>
          <div class="resource_format">
            {{ resource['@label'] }}
          </div>
          <div class="resource_name">
            {{ resource.codename }}
          </div>
        </div>
        <div class="header-bottom">
          <PublicationCheckbox
            :is-main="isMain"
            :meta-resource="metaResource"
            @loading="setLoading"
          />
        </div>
      </div>
      <div class="card-body">
        <ResourceAccordion
          :id="resource.id"
          :is-main="isMain"
          :form-config="$config.forms.resource.geoserver"
          :meta-resource="metaResource"
          v-on="$listeners"
        />
      </div>
    </b-overlay>
    <UpdateGeoserverModal
      :open="isUpdateGeoserverModalOpen"
      :format="mainResourceRoot['@class']"
      @hide="isUpdateGeoserverModalOpen = false"
      @update="modifyGeoserver"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ResourceAccordion from '@/components/Resource/ResourceAccordion.vue';
import PublicationCheckbox from '@/components/Resource/PublicationCheckbox.vue';
import UpdateGeoserverModal from '@/components/Resource/modals/UpdateGeoserverModal.vue';

export default {
  name: 'GeoserverEditor',

  components: {
    ResourceAccordion,
    PublicationCheckbox,
    UpdateGeoserverModal
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      loading: false,

      isUpdateGeoserverModalOpen: false
    };
  },

  computed: {
    ...mapGetters('resources', [
      'mainResourceRoot',
    ])
  },

  methods: {
    setLoading(e) {
      this.loading = e;
    },
    
    modifyGeoserver(e) {
      this.$emit('modify', {
        data: {
          id: this.resource.id,
          ...e.form
        },
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'geoserver',
        resourceId: this.metaResource.id,
      });
      this.isUpdateDatastoreResourceModalOpen = false;
    },
  }
};
</script>

<style lang="less" scoped>
.layers-layergroups {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  fieldset {
    margin-bottom: 0;
  }
}
</style>