<template>
  <div class="annexe-form">
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <ResourceCreator
          ref="resourceCreator"
          :is-main="false"
          :required="required"
          @required="setRequired"
          @set="setForm"
        />
        <b-button
          variant="primary"
          @click="handleSubmit(addAnnexe)"
        >
          {{ $t('buttons.add') }}
        </b-button>
      </ValidationObserver>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import ResourceCreator from '@/components/Resource/ResourceCreator.vue';

import { ValidationObserver } from 'vee-validate';

export default {
  name: 'AddAnnexe',

  components: {
    ResourceCreator,
    ValidationObserver
  },

  props: {
    required: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: false,
      form: {}
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset'
    ])
  },

  mounted() {
    this.$refs.resourceCreator.setRequired(true);
  },

  methods: {
    ...mapMutations('resources', [
      'SET_RESOURCE_INSPECTION_DATA'
    ]),
    ...mapActions('resources', [
      'CREATE_FILE_UPLOAD_ANNEXE',
      'CREATE_FTP_ANNEXE',
      'CREATE_HREF_ANNEXE',
      'CREATE_FILE_DOWNLOAD_ANNEXE',
      'CREATE_DATASTORE_ANNEXE'
    ]),

    setRequired() {
      this.$refs.resourceCreator.setRequired(true);
    },

    setForm(e) {
      this.form = {
        ...this.form,
        ...e,
      };
    },

    addAnnexe() {
      this.loading = true;
      if (this.form.type === 'file-upload') {
        const resourceAnnexeData = {
          ...this.form,
          dataformat_id: this.form.format.id,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset: this.currentDataset.id
        };
        this.createFileUploadAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'ftp') {
        const resourceAnnexeData = {
          ...this.form,
          dataformat_id: this.form.format.id,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset: this.currentDataset.id,
          href: this.form.selectedFtpFiles.map(el => el.value),
        };
        this.createFTPAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'href') {
        const resourceAnnexeData = {
          ...this.form,
          dataformat_id: this.form.format.id,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset: this.currentDataset.id,
          href: this.form.href,
        };
        this.createHrefAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'file-download') {
        const resourceAnnexeData = {
          ...this.form,
          dataformat_id: this.form.format.id,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset: this.currentDataset.id,
          href: this.form.href,
        };
        this.createFileDownloadAnnexe(resourceAnnexeData);
      } else if (this.form.type === 'datastore') {
        const resourceAnnexeData = {
          ...this.form,
          usergroup_id: this.currentDataset.usergroup.id,
          dataset: this.currentDataset.id,
        };
        this.createDatastoreAnnexe(resourceAnnexeData);
      }
    },

    createFileUploadAnnexe(resourceAnnexeData) {
      this.CREATE_FILE_UPLOAD_ANNEXE(resourceAnnexeData)
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createFTPAnnexe(resourceAnnexeData) {
      this.CREATE_FTP_ANNEXE(resourceAnnexeData)
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createHrefAnnexe(resourceAnnexeData) {
      this.CREATE_HREF_ANNEXE(resourceAnnexeData)
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createFileDownloadAnnexe(resourceAnnexeData) {
      this.CREATE_FILE_DOWNLOAD_ANNEXE(resourceAnnexeData)
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createDatastoreAnnexe(resourceAnnexeData) {
      this.CREATE_DATASTORE_ANNEXE(resourceAnnexeData)
        .then(() => {
          this.$emit('close');
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>

.annexe-form {
  margin: 0.5em 0 1em 1em;
  // border: 1px solid #ced4da;
}

button {
  float: right;
  margin: 0.5em 0 1em 0;
  padding: 0.3em 2em 0.3em 2em;
  border: 2px solid #9BD0FF;
  border-radius: 8px;
}

</style>
