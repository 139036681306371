<template>
  <b-modal
    id="confirmation-modal"
    v-model="openModal"
    size="lg"
    :title="$t('modals.deleteDataset.title')"
  >
    <div>
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
      >
        <p>
          {{ $t('modals.deleteDataset.content.dataset', { dataset: dataset.name }) }}
        </p>
        <p>
          {{ $tc('modals.deleteDataset.content.resources', dataset.nbResources, { nbResources: dataset.nbResources }) }}
        </p>
        <p>
          {{ $t('modals.deleteDataset.content.question') }}
        </p>
      </b-overlay>
    </div>
    <template #modal-footer>
      <div class="modal-footer-buttons">
        <b-button
          variant="outline-secondary"
          @click="$emit('close')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          class=""
          @click="$emit('delete-dataset-only')"
        >
          {{ $t('modals.deleteDataset.buttons.datasetOnly') }}
        </b-button>
        <b-button
          variant="primary"
          @click="$emit('delete-dataset-and-resources')"
        >
          {{ $t('modals.deleteDataset.buttons.deleteAll') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteDatasetWithResources',

  props: {
    open: {
      type: Boolean,
      default: false
    },
    dataset: {
      type: Object,
      default: () => {
        return {
          name: '',
          nbResources: 0
        };
      }
    }
  },

  data() {
    return {
      openModal: false,
      loading: false
    }
  },

  watch: {
    open: function(newValue) {
      this.resetModal();
      this.openModal = newValue;
    }
  },

  methods: {
    
    resetModal() {
      this.openModal = false;
      this.loading = false;
    }

  }
}
</script>

<style lang="less" scoped>

h2 {
  color: @blue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

h4 {
  color: @blue;
  margin-top: 0.8em;
}

.modal-footer-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

</style>
