<template>
  <div
    :class="{ disabled: disabled }"
    class="resource_editor"
  >
    <component
      :is="resourceComponent"
      v-if="resourceComponent"
      :ref="isMain ? 'resourceComponent' : 'annexComponent'"
      :resource="resource"
      :meta-resource="metaResource"
      :is-main="isMain"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import DatastoreEditor from '@/components/Resource/editors/DatastoreEditor.vue';
import FileDownloadEditor from '@/components/Resource/editors/FileDownloadEditor.vue';
import FileUploadEditor from '@/components/Resource/editors/FileUploadEditor.vue';
import FTPEditor from '@/components/Resource/editors/FTPEditor.vue';
import GeoserverEditor from '@/components/Resource/editors/GeoserverEditor.vue';
import HrefEditor from '@/components/Resource/editors/HrefEditor.vue';

export default {
  name: 'ResourceEditor',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    resourceComponent() {
      if (this.resource) {
        switch (this.resource['@class']) {
        case 'postgres':
        case 'postgis':
          return DatastoreEditor;
        case 'fileupload':
          return FileUploadEditor;
        case 'ftp':
          return FTPEditor;
        case 'href':
          return HrefEditor;
        case 'filedownload':
          return FileDownloadEditor;
        case 'geographiclayer':
          return GeoserverEditor;
        default:
          return null;
        }
      }
      return null;
    }
  },
};
</script>
